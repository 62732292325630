import TableContainer from "components/Common/TableContainer";
import React, { useMemo } from "react"
import {
  Container,
} from "reactstrap"
import ListaDenunce from "./ListaDenunce";

const Denunce = () => {
  //meta title
  document.title=`Lista Perizie | ${process.env.REACT_APP_DOCUMENT_NAME}`;

  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <ListaDenunce /> 
      </Container>
    </div>
  </React.Fragment>
  );
}

export default Denunce;
