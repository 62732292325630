import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//Users
import Users from "./users/reducer";

//Admin
import Admin from "./admin/reducer";

//Perizie
import Perizie from "./perizie/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Users,
  Admin,
  Perizie
})

export default rootReducer
